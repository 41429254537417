@import "https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;700&display=swap";
body, html, * {
  box-sizing: border-box;
  color: #fff;
  font-family: Work Sans, sans-serif;
}

html, body {
  background-color: #0000;
  margin: 0;
}

header {
  background-color: #061033;
  padding: 0 40px;
}

header .logo {
  width: 100px;
}

.orange {
  color: #ff8a00;
}

.landing {
  position: relative;
}

.landing video {
  z-index: -1;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
}

.landing .video-mask {
  z-index: -1;
  background-color: #061033bb;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
}

.landing .hero-text {
  z-index: 100;
  width: 800px;
  margin: calc(50vh - 160px) auto;
  font-size: 32px;
  display: block;
  position: relative;
}

.section {
  background-color: #061033;
  padding: 20px 0;
}

.specialties {
  text-align: center;
  max-width: 1000px;
  margin: 80px auto 0;
}

.specialties h3 {
  font-size: 32px;
}

.specialties ul {
  font-size: 24px;
  font-style: italic;
  line-height: 32px;
  list-style: none;
}

.projects {
  max-width: 1000px;
  margin: 40px auto 80px;
}

.projects .item {
  align-items: center;
  display: flex;
}

.projects .item:nth-child(2n) {
  flex-direction: row-reverse;
}

.projects .item p {
  padding: 0 20px;
  font-size: 24px;
}

.projects .item img {
  max-width: 300px;
  max-height: 300px;
  padding: 0 20px;
}

.made-by {
  max-width: 1000px;
  margin: 80px auto;
}

.made-by p {
  text-align: center;
}

.made-by ul {
  justify-content: space-around;
  list-style: none;
  display: flex;
}

.made-by ul li > img {
  height: 100px;
}

/*# sourceMappingURL=index.9be321d5.css.map */
