@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;700&display=swap");

$text: #ffffff;
$secondaryText: #ff8a00;
$primaryAction: #00ffe3;
$secondaryAction: #a34bff;
$background: #061033;
$card: #27105c;
$phone: 680px;
body,
html,
* {
  box-sizing: border-box;
  color: $text;
  font-family: "Work Sans", sans-serif;
}

html,
body {
  background-color: #00000000;
  margin: 0;
}

header {
  background-color: $background;
  padding: 0 40px;
}

header .logo {
  width: 100px;
}

.orange {
  color: $secondaryText;
}

// @media (max-width: $phone) {
//   .logo {
//     display: none;
//   }
// }

.landing {
  position: relative;
}

.landing video {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
}

.landing .video-mask {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  background-color: #061033bb;
}

.landing .hero-text {
  position: relative;
  margin: calc(50vh - 160px) auto;
  display: block;
  width: 800px;
  font-size: 32px;
  z-index: 100;
}

.section {
  background-color: $background;
  padding: 20px 0;
}

.specialties {
  margin: 80px auto 0;
  max-width: 1000px;
  text-align: center;
}

.specialties h3 {
  font-size: 32px;
}

.specialties ul {
  font-size: 24px;
  list-style: none;
  font-style: italic;
  line-height: 32px;
}

.projects {
  margin: 40px auto 80px;
  max-width: 1000px;
}

.projects .item {
  display: flex;
  align-items: center;
}

.projects .item:nth-child(2n) {
  flex-direction: row-reverse;
}

.projects .item p {
  font-size: 24px;
  padding: 0 20px;
}

.projects .item img {
  max-width: 300px;
  max-height: 300px;
  padding: 0 20px;
}

.made-by {
  margin: 80px auto;
  max-width: 1000px;
}

.made-by p {
  text-align: center;
}

.made-by ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
}

.made-by ul li > img {
  height: 100px;
}
